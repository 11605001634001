<template>
    <div class="container">
        <div class="align-items-center justify-content-center row h-100vh m-0">
            <div class="login-container col-lg-6">
                <p class="login-tag">Register</p>
                <small>New user</small>
                <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                    <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                        <div class="row">
                            <div class="col-md-12">
                                <validated-input name="Name" label="Name" v-model="model.name" :rules="rules.name"/>
                            </div>
                            <div class="col-md-6">
                                <validated-input name="Username" label="Username" v-model="model.username"
                                                 :rules="rules.username"/>
                            </div>
                            <div class="col-md-6">
                                <validated-input name="Password" label="Password" type="password"
                                                 v-model="model.password"
                                                 v-if="!model.id" :rules="rules.password"/>
                            </div>
                            <div class="col-md-12">
                                <validated-input name="Email" label="Email" v-model="model.email" :rules="rules.email"/>
                            </div>
                            <div class="col-md-6">
                                <validated-input name="Phone" label="Phone Number" v-model="model.phone"
                                                 :rules="rules.phone" @keypress="isNumber"/>
                            </div>
                            <div class="col-md-6">
                                <validated-input name="Whatsapp Number" label="Whatsapp Number"
                                                 v-model="model.whatsapp_number"
                                                 :rules="rules.whatsapp_number" @keypress="isNumber"/>
                            </div>

                            <div class="col-md-12">
                                <b-button type="submit" variant="primary" class="float-right">
                                    Create
                                </b-button>
                            </div>

                        </div>
                    </b-form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'NewUserRegistration',
    components: {},
    data () {
        return {
            model: {
                username: '',
                password: '',
                email: '',
                phone: '',
                whatsapp_number: '',
                name: '',
                role: '3'
            },
            rules: {
                username: {
                    required: true
                },
                password: {
                    required: true
                },
                email: {
                    required: true
                },
                phone: {
                    required: true
                },
                whatsapp_number: {
                    required: false
                },
                name: {
                    required: true
                },
                role: {
                    required: true
                }
            }
        };
    },
    methods: {
        async onSubmit () {
            const url = urls.users.create;
            const response = await axios.form(url, this.model);
            if (response) {
                this.$router.push({ path: '/login/' });
            }
        },
        isNumber: function (evt) {
            evt = (evt) || window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        }
    }
};
</script>

<style scoped>

</style>
